import { ModelSelect } from 'vue-search-select';
import parameterDetails from '../parameterDetails';
export default {
  name: 'templateDetails',
  components: {
    ModelSelect,
    parameterDetails
  },
  data() {
    return {
      loader: false,
      parameterDetails: null,
      showParameterModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: true,
      templateDetails: null,
      showTemplateModal: false,
      requestArr: [],
      requestFormatArr: [],
      templateDetail: {},
      data: [],
      templateForm: {
        selectedRequest: {
          value: null,
          text: null,
          key: null
        },
        active: true,
        report_name: null,
        outputFormat: {
          value: null,
          text: null
        },
        template_id: 0,
        method_name: null,
        template_file: '0',
        template_name: null
      }
    };
  },
  mounted() {
    this.getRequestFormat();
    this.getRequestLovList();
    this.eventBus.$off('templateFormHeader');
    this.eventBus.$on('templateFormHeader', obj => {
      this.editMode = false;
      this.templateDetail = obj;
      this.getTemplateList(obj.template_id);
    });
    this.eventBus.$off('respFormActions');
    this.eventBus.$on('respFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.update = true;
        this.editMode = true;
      }
      if (actionName === 'add') {
        this.editMode = true;
        this.resetTemplateDetails();
      }
      if (actionName === 'save' && !this.showParameterModal) {
        this.addEditTemplate();
      }
      if (actionName === 'upload') {
        this.eventBus.$emit('commonUpload', {
          id: this.templateForm.template_id
        });
      }
    });
  },
  methods: {
    requestSelected(item) {
      this.reqType = item.request_type;
    },
    getParameter() {
      if (this.editMode) {
        this.showParameterModal = true;
        setTimeout(() => {
          this.eventBus.$emit(
            'parameterDetails',
            this.templateForm.template_id
          );
        }, 0);
      }
    },
    getTemplateList(templateId) {
      this.loader = true;
      this.$store
        .dispatch('template/getTemplateByIdList', templateId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.templateForm.selectedRequest = {
              value: result.request_id,
              text: result.request_name,
              key: result.request_type
            };
            this.templateForm.outputFormat = {
              value: result.output_format,
              text: result.request_format
            };
            this.templateForm.active = result.active;
            this.templateForm.report_name = result.report_name;
            this.templateForm.method_name = result.method_name;
            this.templateForm.template_id = result.template_id;
            this.templateForm.template_name = result.template_name;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequestLovList() {
      this.loader = true;
      this.$store
        .dispatch('template/getRequestLovList')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.request_id,
                text: type.request_name,
                key: type.request_type
              };
            });
            this.requestArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditTemplate() {
      const payload = {
        active: this.templateForm.active,
        method_name: this.templateForm.method_name,
        report_name: this.templateForm.report_name,
        request_format: this.templateForm.outputFormat.value,
        request_id: this.templateForm.selectedRequest.value,
        template_file: this.templateForm.template_file,
        template_id: this.templateForm.template_id,
        template_name: this.templateForm.template_name
      };
      this.loader = true;
      this.$store
        .dispatch('template/addTemplateList', payload)
        .then(response => {
          this.loader = false;
          this.$emit('updateTemplateList');
          this.showAlert = true;
          if (response.status === 201) {
            this.templateForm.template_id = response.data.data.template_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequestFormat() {
      this.loader = true;
      this.$store
        .dispatch('template/getLOVBySetType', 'REQUEST_FORMAT')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.requestFormatArr = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resetTemplateDetails() {
      this.templateForm = {
        selectedRequest: {
          value: null,
          text: null,
          key: null
        },
        active: true,
        report_name: null,
        outputFormat: {
          value: null,
          text: null
        },
        template_id: 0,
        method_name: null,
        template_file: '0',
        template_name: null
      };
    },
    resetModal() {}
  }
};
