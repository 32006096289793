import commonHelper from '@/app/utility/common.helper.utility';
import templateDetails from './templateDetails';
import parameterDetails from './parameterDetails';
import { ModelSelect } from 'vue-search-select';
export default {
  name: 'DefineTemplate',
  components: {
    templateDetails,
    parameterDetails,
    ModelSelect
  },
  props: ['showMasterTemplateModal'],
  watch: {
    currentPage: function() {
      this.getTemplateGridList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTemplateGridList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      payload: {},
      showParameterModal: false,
      parameterDetails: null,
      templateDetails: null,
      showTemplateModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      name: {
        value: null,
        text: null
      },
      template: '',
      data: [],
      fields: [
        {
          key: 'request_name',
          label: 'Request Name'
        },
        {
          key: 'template_name',
          label: 'Template'
        },
        {
          key: 'report_name',
          label: 'Report Name'
        },
        {
          key: 'template_id',
          class: 'd-none'
        },
        {
          key: 'request_id',
          class: 'd-none'
        },
        {
          key: 'creation_date',
          label: 'Creation Date'
        },
        {
          key: 'active',
          label: 'Active'
        }
      ],
      requestArr: [],
    };
  },
  mounted() {
    this.getTemplateGridList();
    this.getRequestLovList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;

        if (this.showTemplateModal) {
          this.eventBus.$emit('respFormActions', actionName);
        }
        if (actionName === 'add') {
          this.showTemplateModal = true;
        }
        if (actionName === 'download' && !this.showTemplateModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'template/getTemplateList',
            'template',
            () => (this.loader = false)
          );
        }
      }
    });
  },

  methods: {
    resetModal() {},
    rowSelected(rowData) {
      if (this.showMasterTemplateModal) {
        this.$emit('selectedtemplate', rowData);
      } else {
        setTimeout(() => {
          this.eventBus.$emit('templateFormHeader', rowData);
        }, 0);
        this.showTemplateModal = true;
      }
    },
    clearTemplate() {
      this.name = {
        value: null,
        text: null
      };
      this.template = null;
      this.data = [];
    },
    updateTemplateList() {
      this.getTemplateGridList();
    },
    updateParameterList() {
      this.getTemplateGridList();
    },
    getTemplateGridList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        request_id: this.name.value,
        template_name: this.template
      };
      this.loader = true;
      this.$store
        .dispatch('template/getTemplateList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequestLovList() {
      this.loader = true;
      this.$store.dispatch('template/getRequestLovList').then(response => {
        this.loader = false;
        if (response.status === 200) {
          const results = response.data.data;
          const getValueAndText = results.map(type => {
            return {
              value: type.request_id,
              text: type.request_name
            };
          });
          this.requestArr = getValueAndText;
        }
      })
      .catch(() => {
        this.loader = false;
      });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
