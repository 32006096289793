import appStrings from '@/app/utility/string.utility';
export default {
  name: 'TenantInvoiceDetails',
  props: ['billHdrId', 'tenantInvoiceDetails'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      invoiceDetailsData: {
        bill_hdr_id: null,
        total_amount: null,
        request_id: null,
        bill_no: null,
        request_num: null,
        template_id: null,
        template_name: null,
        customer_id: null,
        customer_name: null,
        customer_site_id: null,
        customer_site: null,
        customer_site_address: null,
        gl_date: null,
        transactional_currency_id: null,
        currency: null,
        le_id: null,
        le_name: null,
        ou_id: null,
        ou_name: null,
        bill_status_vset: null,
        bill_status_vset_meaning: null,
        due_date: null,
        address: null,
        billing_period_start_date: null,
        billing_period_end_date: null,
        source_hdr_id: null,
        source_name: null,
        lease_prj_id: null,
        lease_prj_name: null,
        lease_prj_unit_id: null,
        lease_prj_unit_name: null,
        lease_schedule_type_vset: null,
        lease_schedule_type_vset_meaning: null,
        lease_floor_id: null,
        lease_floor_name: null,
        lease_tower_id: null,
        lease_tower_name: null,
        bill_date: null,
        trx_type: null,
        third_party_gstn: null,
        first_party_gstn: null,
        tenant_po: null,
        bill_line_details: [
          {
            description: null,
            bill_comp_id: null
          }
        ]
      },
      scheduleType: {
        value: null,
        text: null
      },
      selectedBillTemplate: {
        value: null,
        text: null
      },
      selectedBillRequest: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      selectedCurrency: {
        value: null,
        text: null
      },
      selectedSource: {
        value: null,
        text: null
      },
      invoiceBillList: [],
      invoiceBillFields: [
        {
          key: 'lease_schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'description'
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'value_date'
        },

        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'tax_cat_name'
        },
        {
          key: 'tax_amount'
        }
      ]
    };
  },
  computed: {
    isPoBillAvailable() {
      if (this.invoiceDetailsData.tenant_po) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    if (this.billHdrId) {
      this.getTenantInvoiceDetails(this.billHdrId);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditTenantBill();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'delete') {
          if (this.invoiceDetailsData.bill_status_vset == 'P') {
            alert("Processed record can't be deleted.");
          } else {
            const toDelete = confirm(
              'Are you sure, you want to delete this record?'
            );
            if (toDelete) {
              this.deleteTenantBill();
            }
          }
        }
      }
    });
  },
  methods: {
    getTenantInvoiceDetails(billHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/getTenantInvoiceDetails', billHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.invoiceDetailsData = response.data.data;
            // this.invoiceDetailsData = response.data.data.bill_line_details.map((item) => {
            //   return {
            //     description: item.description
            //   }
            // })
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateTenantBill() {
      const payload = {
        lease_tenant_agreement_hdr_id: this.tenantInvoiceDetails
          .lease_tenant_agreement_hdr_id,
        tenant_po: this.invoiceDetailsData.tenant_po
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/updateTenantBill', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    updateBill(poBill) {
      if (poBill) {
        this.updateTenantBill();
      } else {
        alert('Please enter PO Bill');
      }
    },
    addEditTenantBill() {
      const tenantBillDetails = this.invoiceDetailsData.bill_line_details.map(
        elem => {
          return {
            description: elem.description,
            bill_comp_id: elem.bill_comp_id
          };
        }
      );
      const payload = {
        lease_bill_line_update: tenantBillDetails ? tenantBillDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/addEditTenantBill', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    deleteTenantBill() {
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/deleteTenantBill', this.billHdrId)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.$emit('onDeletion');
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
