import { ModelSelect } from 'vue-search-select';
import valueSet from '../valueSet';
export default {
  name: 'parameterDetails',
  components: { ModelSelect, valueSet },
  data() {
    return {
      loader: false,
      respTableIndex: 0,
      showValuesetForm: false,
      parameterTypeModal: false,
      parameterDetails: null,
      templateList: [],
      templateData: [
        {
          key: 'text',
          label: 'Type Parameter'
        }
      ],
      parameterDetailsobj: null,
      display: null,
      showParameterModal: false,
      strictWidth: null,
      variableWidth: { value: null, text: null },
      typeArr: [{ value: null, text: null }],
      editMode: true,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      data: [
        {
          template_id: null,
          select_type_meaning: null,
          select_type_value: null,
          selectedValueset: {
            value: null,
            text: null
          },
          parameter_id: 0,
          width_id: null,
          mandatory: false,
          display_flag: false,
          group_by_flag: false,
          parameter_show: null,

          display_id: null,
          width: null,
          default_value: null,
          multi_flag: false,
          parameter_name: null,
          param_seq: 1,
          in_out: null
        }
      ],
      fields: [
        {
          key: 'param_seq',
          label: 'Sequence'
        },
        {
          key: 'display_id',
          label: 'Display Name'
        },
        {
          key: 'parameter_id',
          class: 'd-none'
        },
        {
          key: 'parameter_name'
        },
        {
          key: 'template_id',
          class: 'd-none'
        },
        {
          key: 'parameter_type',
          label: 'Type'
        },
        {
          key: 'parameter_show',
          class: 'd-none'
        },
        {
          key: 'vset_id',
          class: 'd-none'
        },
        {
          key: 'vset_name',
          class: 'd-none'
        },
        {
          key: 'width',
          label: 'Width'
        },
        {
          key: 'mandatory',
          label: 'Mandatory'
        },
        {
          key: 'multi_flag',
          label: 'Multi Flag'
        },
        {
          key: 'display_flag',
          label: 'Display Flag'
        },
        {
          key: 'group_by_flag'
        },
        {
          key: 'default_value',
          label: 'Default Value'
        },
        {
          key: 'in_out',
          label: 'IN/OUT'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      parameterForm: {
        template_id: null,
        parameter_details: null
      }
    };
  },
  mounted() {
    this.getParameterTypeFormat();
    this.eventBus.$on('parameterDetails', templteId => {
      this.editMode = true;
      this.parameterDetailsobj = templteId;
      this.getParameterMapping(templteId);
    });
    this.eventBus.$on('respFormActions', actionName => {
      if (actionName === 'edit' || actionName === 'update') {
        this.editMode = true;
        this.update = true;
      }
      if (actionName === 'add') {
        this.editMode = true;
      }
      if (actionName === 'save' && this.editMode) {
        this.addEditParameter();
      }
    });
  },
  methods: {
    widthType(index) {
      if (this.data[index].select_type_value !== 'VALUESET') {
        if (this.data[index].select_type_value === 'DATE') {
          this.data[index].width = 20;
        }
        this.data[index].width_id = this.data[index].width;
      } else {
        this.openValueSet(index);
      }
    },
    openValueSet(index) {
      if (this.editMode) {
        this.respTableIndex = index;
        this.showValuesetForm = true;
      }
    },
    selectedvalueSet(vsetName) {
      this.data[this.respTableIndex].width = vsetName.value_set_name;
      this.data[this.respTableIndex].width_id = vsetName.value_set_hdr_id;
      this.showValuesetForm = false;
    },
    getParameterTypeFormat() {
      this.loader = true;
      this.$store
        .dispatch('template/getLOVBySetType', 'PARAMETER_TYPE')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const getValueAndText = results.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning
              };
            });
            this.templateList = getValueAndText;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    parameterModal(flag, index) {
      this.parameterIndex = index;
      this.parameterTypeModal = flag;
    },
    selectedParameterTemplate(item) {
      this.data[this.parameterIndex].select_type_meaning = item.text;
      this.data[this.parameterIndex].select_type_value = item.value;
      this.parameterTypeModal = false;
    },
    getParameterMapping(templateId) {
      this.loader = true;
      this.$store
        .dispatch('template/getRequestParameterList', templateId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const detail = response.data.data;
            if (detail && detail.length > 0) {
              const resultList = detail.map(obj => {
                this.display = obj.width;
                return {
                  template_id: obj.template_id,
                  // select_type: {
                  //   value: obj.parameter_type,
                  //   text: obj.parameter_type
                  // },
                  select_type_meaning: obj.parameter_type,
                  select_type_value: obj.parameter_type,
                  parameter_id: obj.parameter_id,
                  mandatory: obj.mandatory,
                  display_flag: obj.display_flag,
                  parameter_show: obj.parameter_show,
                  group_by_flag: obj.group_by_flag,
                  selectedValueset: {
                    value: obj.vset_id,
                    text: obj.vset_name
                  },
                  display_id: obj.display_id,
                  width_id: this.display ? obj.width : obj.vset_id,
                  width: this.display ? obj.width : obj.vset_name,
                  default_value: obj.default_value,
                  parameter_name: obj.parameter_name,
                  multi_flag: obj.multi_flag,
                  param_seq: obj.param_seq,
                  in_out: obj.parameter_in_out
                };
              });
              this.data = resultList;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.data.push({
          template_id: null,
          // select_type: { value: null, text: null },
          select_type_meaning: null,
          select_type_value: null,
          selectedValueset: {
            value: null,
            text: null
          },
          parameter_id: 0,
          in_out: null,
          mandatory: false,
          display_flag: false,
          parameter_show: null,
          group_by_flag: false,
          display_id: null,
          width: null,
          width_id: null,
          default_value: null,
          parameter_name: null,
          multi_flag: false,
          param_seq: this.data.length + 1
        });
      }
    },
    removeRow(index) {
      this.data.splice(index, 1);
    },
    addEditParameter() {
      const details = this.data.map(paramDetails => {
        return {
          defalut_value: paramDetails.default_value,
          display_flag: paramDetails.display_flag,
          display_name: paramDetails.display_id,
          mandatory: paramDetails.mandatory,
          multi_flag: paramDetails.multi_flag,
          param_seq: paramDetails.param_seq,
          parameter_id: paramDetails.parameter_id,
          parameter_name: paramDetails.parameter_name,
          parameter_type: paramDetails.select_type_value,
          group_by_flag: paramDetails.group_by_flag,
          width_vset_id: paramDetails.width_id
            ? paramDetails.width_id
            : paramDetails.width,
          in_out: paramDetails.in_out
        };
      });
      const payload = {
        template_id: this.parameterDetailsobj,
        parameter_details: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('template/addParameterList', payload)
        .then(response => {
          this.loader = false;
          this.$emit('updateParameterList');
          this.showAlert = true;
          if (response.status === 201) {
            this.data.parameter_id = response.data.data.parameter_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })        .catch(() => {
          this.loader = false;
        });
    },
    resetModal() {}
  },
  destroyed() {
    this.eventBus.$off('respFormActions');
    this.eventBus.$off('parameterDetails');
  }
};
