import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'valueSet',
  watch: {
    currentPage: function() {
      this.getValueSetList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getValueSetList();
    }
  },
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      name: '',
      data: [],
      fields: [
        {
          key: 'value_set_hdr_id',
          class: 'd-none'
        },
        {
          key: 'value_set_name',
          label:'Value Set Name'
        },
        {
            key:'description',
            label:'Description'
        }
      ]
    };
  },
  mounted() {
    this.getValueSetList();
  },
  methods: {
    clearValueSet(){
      this.name = null
    },
    rowSelected(rowData){
      this.$emit('selectedvalueSet', rowData)
    },
    getValueSetList() {
      const payload = {
        value_set_name: this.name
      };
      this.$store
        .dispatch('template/getValuesetLovList', payload)
        .then(response => {
          this.data = response.data.data;
        });
    }
  }
};
